<template>
  <div>
    indextest
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "index",
  components:{

  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('infoModule', ['getToken'])
  },
  created() {
    this.checkLogin()
  },
  mounted() {

  },
  methods: {
    checkLogin(){
      if(this.getToken == null || this.getToken == '' || this.getToken == undefined){
        this.$router.push({name:'login'})
      }else{
        this.$router.push({name:'home'})
      }
    }
  },
}
</script>

